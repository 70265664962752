import styled from 'styled-components';

export const PageTitle = styled.p`
  font-family: Libre Baskerville;
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  color: ${({ theme }) => theme.colors.navy};

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: 18px;
    margin: auto;
  }
`;

export const StepAndButtonContainer = styled.div`
  display:inline-block;
  overflow: auto;
  white-space: nowrap;
  margin:0px auto;
  width: 100%;
  max-width: 1075px;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    padding: 20px;
    margin: 30px 0px 60px;
    max-width: 100vw;
    box-sizing: border-box;
  }
`;

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
