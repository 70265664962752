import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Image, Transformation, Placeholder } from 'cloudinary-react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import withAppContext from '../../withAppContext';
import withUptradeContext from '../../withUptradeContext';
import { colors } from '../home/v3/styledComponents';

import Cloudinary from '../Cloudinary';
import './slider.css';

const MainContainer = styled.div`
  position: relative;
  margin: 0px 10px;
  &:hover ${(props) => `#${props.cardId}`}{
    bottom: 126px;
  }

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    width: 100%;
    margin: 0px;
    &:hover ${(props) => `#${props.cardId}`}{
      bottom: 116px;
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 345px;
  margin-bottom: 40px;
  cursor: pointer;
  box-shadow: 0px 1px 6px ${({ theme }) => theme.colors.blueGrey};

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    width: 100%;
    margin-bottom: 30px;
  }
`;

const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 210px;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    width: 100%;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 17px;
  height: 86px;
  background-color: ${({ isActive, theme }) => theme.colors[isActive ? 'navy' : 'white']};
  color: ${({ isActive, theme }) => theme.colors[isActive ? 'white' : 'navy']};
  box-sizing: border-box;
  justify-content: space-between;
  z-index: 1;
`;

const NameAndPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
`;

const Name = styled.p`
  font-family: Libre Baskerville;
  font-weight: bold;
  font-size: 18px;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const Price = styled.p`
  font-family: Roboto;
  font-weight: 500;
  font-size: 22px;
  margin-left: 5px;
`;

const Description = styled.p`
  font-family: Roboto;
  font-size: 15px;
  line-height: 28px;
`;

const KnowMoreCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 345px;
  height: 54px;
  background-color: ${({ theme }) => theme.colors.whiteTransparent2};
  color: ${({ theme }) => theme.colors.navy};
  bottom: 45px;
  font-family: Libre Baskerville;
  font-style: italic;
  font-size: 15px;
  transition: bottom 0.3s ease;
  cursor: pointer;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    bottom: 116px;
    width: 100%;
  }
`;

const DesktopImage = styled(Image)`
  max-width: 345px;
  overflow: hidden;
  &:before {
    content: ' ';
    display: block;
    position: absolute;
    width: 345px;
    height: 210px;
    background: ${colors.lightGrey2};
  }

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    max-width: unset;
  }
`;

const MobileImage = styled(DesktopImage)`
  width: 100%;
  height: 281px;
  object-fit: cover;

  &:before {
    width: 100%;
    height: 281px;
  }
`;

const renderImage = (index, image, productName, isMobile) => (
  <ImgContainer key={`${image}_${index}`}>
    <Cloudinary>
      {isMobile
        ? (
          <MobileImage responsive width="auto" publicId={image} type="fetch" alt={productName}>
            <Transformation quality="auto:best" />
            <Transformation effect="unsharp_mask" />
            <Transformation gravity="center" width="auto:1" height="281" crop="fill" dpr="auto" />
            <Placeholder type="blur" />
          </MobileImage>
        )
        : (
          <DesktopImage publicId={image} type="fetch" alt={productName}>
            <Transformation height="210" quality="auto:best" width="345" crop="fill" />
            <Placeholder type="blur" />
          </DesktopImage>
        )}
    </Cloudinary>
  </ImgContainer>
);

const ProductCard = ({
  product, uptradeContext: { partnerProducts, toggleProduct }, showMoreInfos, context: { isMobile },
}) => {
  const productName = product.description || product.material;
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dotsClass: 'slick-dots custom-dots',
    className: 'slick-slider custom-slick-slider',
    slide: 'Slide',
  };
  return (
    <MainContainer cardId={product.id}>
      <Container onClick={() => toggleProduct(product)}>
        <Slider {...settings}>
          {product.thumbnails.map((thumbnail, index) => renderImage(index, thumbnail, productName, isMobile))}
        </Slider>
        <TextContainer isActive={partnerProducts.find(({ id }) => id === product.id)}>
          <NameAndPriceContainer>
            <Name>{productName}</Name>
            <Price>{`${product.price}€`}</Price>
          </NameAndPriceContainer>
          <Description>{`Métrage : ${product.length}m - Prix au m : ${Math.round(product.cost * 100) / 100}€`}</Description>
        </TextContainer>
      </Container>
      <KnowMoreCard id={product.id} onClick={showMoreInfos}>
        En savoir plus
      </KnowMoreCard>
    </MainContainer>
  );
};

ProductCard.propTypes = {
  uptradeContext: PropTypes.shape({
    partnerProducts: PropTypes.arrayOf(PropTypes.shape({})),
    toggleProduct: PropTypes.func,
  }).isRequired,
  product: PropTypes.shape({
    id: PropTypes.string,
    thumbnails: PropTypes.arrayOf(PropTypes.string),
    description: PropTypes.string,
    material: PropTypes.string,
    price: PropTypes.number,
    cost: PropTypes.number,
    length: PropTypes.number,
    width: PropTypes.string,
  }).isRequired,
  showMoreInfos: PropTypes.func.isRequired,
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
};

export default withAppContext(withUptradeContext(ProductCard));
