import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import withAppContext from '../../withAppContext';
import OutInSideClick from '../home/v2/OutInSideClick';
import { renderArrowUp } from './svgImages';

const Container = styled.div`
  position: relative;
  cursor: pointer;
  margin-right: 22px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.navy};
  border: 1px solid ${({ hasNavyBorder, theme }) => theme.colors[hasNavyBorder ? 'navy' : 'blueGrey']};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  height: 42px;
  box-sizing: border-box;
  font-family: Roboto;
  font-weight: 500;
  font-size: 14px;
  &:active {
    border-color: ${({ theme }) => theme.colors.navy};
  }

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 15px;
  }
`;

const NumberOfFilters = styled.div`
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ isNavy, theme }) => theme.colors[isNavy ? 'navy' : 'white']};
  padding: 1px 4px;
  min-width: 16px;
  height: 100%;
  margin: 0px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const List = styled.div`
  min-width: 100%;
  position: absolute;
  top: 44px;
  left: -1px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  max-height: 150px;
  border: 1px solid ${({ theme }) => theme.colors.blueGrey};
  border-radius: 0px 0px 3px 3px;
  width: fit-content;
  z-index: 4;
  overflow: hidden;
  &:hover {
    overflow-y: auto;
  }

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    overflow-y: auto;
    ${({ isMobileRight }) => isMobileRight && 'right: -1px; left: unset;'}
  }
`;

const ListRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px;
  height: 36px;
  box-sizing: border-box;
  font-family: Roboto;
  font-weight: ${({ isSelected }) => isSelected ? 500 : 'normal'};
  ${({ isSelected, theme }) => isSelected && `background-color: ${theme.colors.blueGrey};`}
  font-size: 14px;
  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.blueGrey};
  }
`;

const FilterSelect = ({
  label: filterLabel, list, filters, toggleFilter, context: { isMobile }, index,
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const selectFilter = (value) => {
    toggleFilter(value);
    setIsOpened(false);
  };
  return (
    <OutInSideClick handleClickOutside={() => setIsOpened(false)} style={isMobile ? { width: 'calc(50% - 7px)' } : undefined}>
      <Container hasNavyBorder={filters.length > 0 || isOpened} onClick={() => setIsOpened(!isOpened)}>
        {filterLabel}
        <NumberOfFilters isNavy={filters.length > 0}>{filters.length}</NumberOfFilters>
        {renderArrowUp({ isDown: isOpened })}
        {isOpened && (
          <List isMobileRight={index % 2 !== 0}>
            {list.map(({ value, label }) => (
              <ListRow
                key={value}
                isSelected={filters.includes(value)}
                onClick={() => selectFilter(value)}
              >
                {label}
              </ListRow>
            ))}
          </List>
        )}
      </Container>
    </OutInSideClick>
  );
};

FilterSelect.propTypes = {
  label: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.shape({})),
  filters: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  toggleFilter: PropTypes.func.isRequired,
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
  index: PropTypes.number.isRequired,
};

FilterSelect.defaultProps = {
  list: [],
  filters: [],
};

export default withAppContext(FilterSelect);
