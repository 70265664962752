import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate } from 'gatsby';

import Layout from '../../layouts/index';
import routesMap from '../../Routes';
import withUptradeContext from '../../withUptradeContext';
import MenuBar from '../../components/home/MenuBar';
import Footer from '../../components/home/v3/Footer';
import ProductsSelector from '../../components/TilliUptrade/ProductsSelector';
import ProgressBar from '../../components/TilliUptrade/ProgressBar';
import { Container, Content } from '../../components/home/v3/styledComponents';
import { StepAndButtonContainer, StepContainer } from '../../components/TilliUptrade/styledComponents';
import arrow from '../../assets/tilliUptrade/arrow.png';

import './style.css';

const Button = styled.button`
  position: fixed;
  bottom: 46px;
  right: 253px;
  width: 156px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.navy};
  font-family: Roboto;
  font-weight: 500;
  font-size: 14px;
  border-style: none;
  box-shadow: 0px 3px 8px ${({ theme }) => theme.colors.popup};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 2;
  cursor: pointer;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    position: unset;
    width: 146px;
  }
`;

const ButtonArrow = styled.img`
  width: 15px;
  margin-left: 10px;
`;

const ProductsSelectorPage = ({ uptradeContext: { apiPartnerProducts, partnerProducts, fetchApiPartnerProducts } }) => {
  useEffect(() => {
    if (!(apiPartnerProducts?.length > 0)) { fetchApiPartnerProducts(); }
  }, []);

  return (
    <Layout routeSlug="ProductsSelectorPage">
      <Container>
        <MenuBar showGiftBanner={false} />
        <Content hasNoBanner>
          <StepAndButtonContainer>
            <StepContainer>
              <ProgressBar step={1} />
              <ProductsSelector />
              <Button onClick={() => partnerProducts.length > 0 && navigate(routesMap.DeliveryInfosPage.url)}>
                Étape suivante
                <ButtonArrow src={arrow} alt="Étape suivante" />
              </Button>
            </StepContainer>
          </StepAndButtonContainer>
        </Content>
        <Footer />
      </Container>
    </Layout>
  );
};

ProductsSelectorPage.propTypes = {
  uptradeContext: PropTypes.shape({
    apiPartnerProducts: PropTypes.arrayOf(PropTypes.shape({})),
    partnerProducts: PropTypes.arrayOf(PropTypes.shape({})),
    fetchApiPartnerProducts: PropTypes.func,
  }).isRequired,
};

export default withUptradeContext(ProductsSelectorPage);
