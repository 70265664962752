import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import withAppContext from '../../withAppContext';
import { renderArrowLeft, renderArrowRight } from './svgImages';

import arrow from '../../assets/tilliUptrade/arrow.png';
import cross from '../../assets/tilliUptrade/cross.png';

const Container = styled.div`
  width: 955px;
  padding: 24px 51px 41px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${({ theme }) => theme.colors.navy};

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    width: 100%;
    box-sizing: border-box;
    padding: 25px 25px 42px;
  }
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const Return = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-family: Roboto;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
`;

const ReturnImg = styled.img`
  width: 15px;
  margin-right: 10px;
  transform: rotate(180deg);
`;

const ModalCross = styled.img`
  height: 17px;
  width: 17px;
  cursor: pointer;
`;

const Title = styled.p`
  font-family: Libre Baskerville;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 13px;
  text-align: center;
`;

const Th = styled.th`
  border: 1px solid ${({ theme }) => theme.colors.lightGrey3};
  height: 54px;
  padding: 0px 24px;
  text-align: left;
  width: 50%;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    padding: 5px 10px;
  }
`;

const Td = styled.td`
  border: 1px solid ${({ theme }) => theme.colors.lightGrey3};
  height: 38px;
  padding: 0px 24px;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    padding: 5px 10px;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid ${({ theme }) => theme.colors.lightGrey3};
  white-space: pre-wrap;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    margin-bottom: 20px;
  }
`;

const Thead = styled.thead`
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.navy};
  font-family: Libre Baskerville;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
`;

const Tbody = styled.tbody`
  font-family: Roboto;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
`;

const SubLabel = styled.span`
  margin-left: 5px;
  font-weight: normal;
  font-size: 12px;
`;

const PageCountContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
`;

const PageCount = styled.p`
  margin: 0px 11px;
  font-family: Roboto;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
`;

const projectsAndLengths = [
  {
    project: { label: 'Canapé', subLabel: '(Structure + assise + dossier) 2 places' },
    length: { label: '12m', subLabel: '+ 1,5 fois si tissu à motif' },
  },
  {
    project: { label: 'Canapé', subLabel: '3 places' },
    length: { label: '15m', subLabel: '+ 1,5 fois si tissu à motif' },
  },
  {
    project: { label: 'Canapé', subLabel: '4 places' },
    length: { label: '18m', subLabel: '+ 1,5 fois si tissu à motif' },
  },
  {
    project: { label: 'Canapé', subLabel: '5 places' },
    length: { label: '21m', subLabel: '+ 1,5 fois si tissu à motif' },
  },
  {
    project: { label: 'Fauteuil' },
    length: { label: '5m', subLabel: '+ 1,5 fois si tissu à motif' },
  },
  {
    project: { label: 'Grand pouf' },
    length: { label: '2,5m', subLabel: '+ 1,5 fois si tissu à motif' },
  },
  {
    project: { label: 'Petit tabouret' },
    length: { label: '1m', subLabel: '+ 1,5 fois si tissu à motif' },
  },
  {
    project: { label: 'Chaise', subLabel: 'sans accoudoirs' },
    length: { label: '1,5m', subLabel: '+ 1,5 fois si tissu à motif' },
  },
  {
    project: { label: 'Chaise', subLabel: 'avec accoudoirs' },
    length: { label: '2m', subLabel: '+ 1,5 fois si tissu à motif' },
  },
  {
    project: { label: 'Création d’accessoires' },
    length: { label: '5m', subLabel: '+ 1,5 fois si tissu à motif' },
  },
];

const SizeGuide = ({ returnToInfos, close, context: { isMobile } }) => {
  const [perPage] = useState(isMobile ? 5 : projectsAndLengths.length);
  const [offset, setOffset] = useState(0);

  const handlePageClick = (offsetValue) => {
    setOffset(offsetValue);
  };

  const pageCount = Math.round(projectsAndLengths.length / perPage, 10);

  return (
    <Container>
      <HeaderRow>
        <Return onClick={returnToInfos}>
          <ReturnImg src={arrow} alt="Retour sur les infos produits" />
          Retour
        </Return>
        <ModalCross src={cross} alt="Fermer les infos produits" onClick={close} />
      </HeaderRow>
      <Title>Guide des tailles</Title>
      <Table>
        <Thead>
          <tr>
            <Th>Projet</Th>
            <Th>Métrage</Th>
          </tr>
        </Thead>
        <Tbody>
          {projectsAndLengths.slice(offset * perPage, (offset * perPage) + perPage).map(({ project, length }) => (
            <tr key={`${project.label}_${length.label}`}>
              <Td>
                {project.label}
                {project.subLabel ? <SubLabel>{project.subLabel}</SubLabel> : ''}
              </Td>
              <Td>
                {length.label}
                {length.subLabel ? <SubLabel>{length.subLabel}</SubLabel> : ''}
              </Td>
            </tr>
          ))}
        </Tbody>
      </Table>
      {isMobile && (
        <PageCountContainer>
          {renderArrowLeft({ isActive: offset !== 0, onClick: () => handlePageClick(offset - 1) })}
          <PageCount>{`${offset + 1} / ${pageCount}`}</PageCount>
          {renderArrowRight({ isActive: offset !== pageCount - 1, onClick: () => handlePageClick(offset + 1) })}
        </PageCountContainer>
      )}
    </Container>
  );
};

SizeGuide.propTypes = {
  returnToInfos: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
};

export default withAppContext(SizeGuide);
