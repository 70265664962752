/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';

const Arrow = styled.svg`
  ${({ isActive }) => !isActive && 'pointer-events: none;'}
  stroke: ${({ isActive, theme }) => theme.colors[isActive ? 'navy' : 'lightGrey3']};
  ${({ mustRotate }) => mustRotate && 'transform: rotate(180deg);'}
  transition: stroke 1s, transform 1s;
`;

export const renderArrowLeft = ({ isActive, onClick }) => (
  <Arrow onClick={onClick} isActive={isActive} width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.12988 1.0011L1.12988 8.5011L9.12988 16.0011" strokeWidth="1.43269" strokeLinecap="round" strokeLinejoin="round" />
  </Arrow>
);

export const renderArrowRight = ({ isActive, onClick }) => (
  <Arrow onClick={onClick} isActive={isActive} width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.870361 1.0011L8.87036 8.5011L0.870361 16.0011" strokeWidth="1.43269" strokeLinecap="round" strokeLinejoin="round" />
  </Arrow>
);

export const renderArrowUp = ({ isDown }) => (
  <Arrow isActive mustRotate={!isDown} width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 6.5L6 1.5L11 6.5" strokeWidth="1.5" />
  </Arrow>
);

const SVG = styled.svg`
  ${({ showStroke, isActive, theme }) => showStroke && `stroke: ${theme.colors[isActive ? 'white' : 'navy']};`};
  ${({ showFill, isActive, theme }) => showFill && `fill: ${theme.colors[isActive ? 'white' : 'navy']};`};
  ${({ mustRotate }) => mustRotate && 'transform: rotate(180deg);'}
  transition: stroke 1s, fill 1s, transform 1s;
`;

export const renderFilter = ({ isActive }) => (
  <SVG showFill isActive={isActive} width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.125 17.7105V19.7939H9.375V17.7105H3.125ZM3.125 5.21053V7.29386H13.5417V5.21053H3.125ZM13.5417 21.8772V19.7939H21.875V17.7105H13.5417V15.6272H11.4583V21.8772H13.5417ZM7.29167 9.3772V11.4605H3.125V13.5439H7.29167V15.6272H9.375V9.3772H7.29167ZM21.875 13.5439V11.4605H11.4583V13.5439H21.875ZM15.625 9.3772H17.7083V7.29386H21.875V5.21053H17.7083V3.1272H15.625V9.3772Z" />
  </SVG>
);

export const renderArrowDown = ({ isActive }) => (
  <SVG showStroke isActive={isActive} mustRotate={!isActive} width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 6.5L6 1.5L11 6.5" strokeWidth="1.5" />
  </SVG>
);
