import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ModalContainer = styled.div`
  position: fixed;
  width: 100vw;
  min-height: 100vh;
  z-index: 3;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: white;
  z-index: 3;
  position: relative;
  overflow-y: scroll;
  max-height: calc(100vh - 20px);
  margin: 10px;
`;

const ModalShadow = styled.div`
  position: fixed;
  width: 100vw;
  min-height: 100vh;
  z-index: 3;
  background-color: rgba(0,0,0,0.35);
  top: 0;
  left: 0;
`;

const Modal = ({ show, children }) => {
  if (!show) {
    return null;
  }
  return (
    <div>
      <ModalShadow />
      <ModalContainer>
        <ModalContent>
          {children}
        </ModalContent>
      </ModalContainer>
    </div>
  );
};

Modal.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Modal.defaultProps = {
  show: false,
};

export default Modal;
