import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import greyImg from '../../assets/tilliUptrade/greyImg.jpg';

const ModalImg = styled.img`
  width: 414px;
  height: 100%;
  object-fit: cover;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    display: none;
  }
`;

const ModalImgContainer = ({ image, productName }) => {
  let src = image;

  const addDefaultSrc = (event) => {
    src = greyImg;
    event.target.src = greyImg;
    event.target.onError = null;
  };

  const addCorrectSrc = (event) => {
    if (event) event.target.src = src;
  };

  return (
    <ModalImg
      src={image}
      alt={productName}
      data-src={greyImg}
      onLoad={(event) => addCorrectSrc(event)}
      onError={(event) => addDefaultSrc(event)}
    />
  );
};

ModalImgContainer.propTypes = {
  image: PropTypes.string,
  productName: PropTypes.string.isRequired,
};

ModalImgContainer.defaultProps = {
  image: undefined,
};

export default ModalImgContainer;
