import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { WindowScroller, List } from 'react-virtualized';
import ReactSlider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './slider.css';

import withAppContext from '../../withAppContext';
import withUptradeContext from '../../withUptradeContext';
import { getFilteredApiPartnerProducts, sortApiPartnerProducts } from '../../services/partnerProductFormatting';

import ProductCard from './ProductCard';
import ModalImgContainer from './ModalImgContainer';
import { PageTitle as StyledPageTitle } from './styledComponents';
import { renderArrowLeft, renderArrowRight } from './svgImages';
import FilterSelect from './FilterSelect';
import FilterButton from './FilterButton';
import Modal from './Modal';
import SizeGuide from './SizeGuide';
import cross from '../../assets/tilliUptrade/cross.png';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.lightGrey};
  color: ${({ theme }) => theme.colors.navy};

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
  }
`;

const PageTitle = styled(StyledPageTitle)`
  margin-bottom: 60px;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    margin-bottom: 44px;
  }
`;

const NumberOfProducts = styled.p`
  font-family: Libre Baskerville;
  font-weight: bold;
  font-size: 14px;
  line-height: 30px;
  margin-bottom: 10px;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    margin-bottom: 15px;
  }
`;

const ProductCardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 120px;
`;

const PageCountContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px auto 40px;
`;

const PageCount = styled.p`
  margin: 0px 11px;
  font-family: Roboto;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
`;

const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 15px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  margin: 0px -10px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MobileImg = styled.img`
  width: 100%;
  object-fit: cover;
  margin-bottom: 10px;

  :last-child {
    margin-bottom: 0px;
  }
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-family: Roboto;
  font-size: 15px;
  line-height: 22px;
  font-weight: 400;
  width: fit-content;
  color: ${({ theme }) => theme.colors.navy};
  background-color: ${({ theme }) => theme.colors.white};

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    flex-direction: column-reverse;
    width: calc(100vw - 20px);
  }
`;

const InfosContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 56px 45px;
  min-width: 429px;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    width: 100%;
    min-width: unset;
    box-sizing: border-box;
    padding: 25px 25px 42px;
  }
`;

const InfoLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
  width: 100%;
`;

const InfoValue = styled.p`
  font-weight: 500;
  margin-left: 10px;
  white-space: pre-wrap;
  text-align: right;
`;

const InfoName = styled.p`
  font-family: Libre Baskerville;
  font-weight: bold;
  font-size: 26px;
  line-height: 30px;
  padding-bottom: 10px;
  width: 100%;
  margin-bottom: 40px;
  border-bottom: solid 1px ${({ theme }) => theme.colors.lightGrey3};
  white-space: pre-wrap;
`;

const ModalCross = styled.img`
  height: 17px;
  width: 17px;
  align-self: flex-end;
  margin-bottom: 3px;
  cursor: pointer;
`;

const Slider = styled(ReactSlider)`
  width: 414px;
  height: auto;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    display: none;
  }
`;

const ShowSizeGuideButton = styled.button`
  margin-top: 38px;
  width: 100%;
  border: 1.5px solid ${({ theme }) => theme.colors.navy};
  color: ${({ theme }) => theme.colors.navy};
  background-color: ${({ theme }) => theme.colors.white};
  font-family: Roboto;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  cursor: pointer;
`;

const Samples = styled.p`
  font-family: Roboto;
  font-size: 15px;
  line-height: 22px;
  font-weight: 400;
  margin-bottom: 40px;
  max-width: 800px;
  white-space: pre-wrap;
  text-align: center;
  align-self: center;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    width: 100%;
  }
`;

const productInfo = [
  { slug: 'length', label: 'Métrage', suffix: ' m' },
  { slug: 'width', label: 'Largeur' },
  { slug: 'cost', label: 'Prix au m', suffix: ' € / m' },
  { slug: 'weight', label: 'Poids' },
  { slug: 'materialRaw', label: 'Composition' },
  { slug: 'standard', label: 'Normes / Certifications / Labels', isArray: true },
  { slug: 'country', label: 'Pays d’origine' },
  { slug: 'pattern', label: 'Motif' },
  { slug: 'colorRaw', label: 'Couleur(s)' },
  { slug: 'reference', label: 'Référence' },
];

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  dotsClass: 'slick-dots modal-dots',
  className: 'slick-slider modal-slick-slider',
};

const rowRenderer = ({
  key,
  index,
  style,
}, list, setProduct) => (
  <Row key={key} style={style}>
    {[0, 1, 2].map((number) => {
      const indexInList = number + index * 3;
      if (indexInList >= list.length) return null;
      const product = list[indexInList];
      return (
        <ProductCard key={product.id} showMoreInfos={() => setProduct(product)} product={product} />
      );
    })}
  </Row>
);

const renderDesktop = (partnerProducts, setProduct) => (
  <WindowScroller>
    {({
      height, isScrolling, onChildScroll, scrollTop,
    }) => (
      <List
        autoHeight
        isScrolling={isScrolling}
        onScroll={onChildScroll}
        width={1075}
        height={height}
        rowCount={Math.ceil(partnerProducts.length / 3)}
        rowHeight={336}
        rowRenderer={(props) => rowRenderer(props, partnerProducts, setProduct)}
        scrollTop={scrollTop}
      />
    )}
  </WindowScroller>
);

const renderMobile = (apiProducts, begin, end, setProduct) => (
  apiProducts.slice(begin, end).map((apiProduct) => (
    <ProductCard key={apiProduct.id} product={apiProduct} showMoreInfos={() => setProduct(apiProduct)} />
  ))
);

const renderHeader = () => (
  <>
    <PageTitle>Sélection de tissus</PageTitle>
    <Samples>
      Si vous désirez commander des échantillons, contactez directement notre service client à contact@tilli.fr et donnez-nous les références des tissus.
      Vous trouverez ces références en cliquant sur “En savoir plus” sur le tissu qui vous intéresse.
    </Samples>
  </>
);

const renderInfosModal = (product, close, showSizeGuide, setShowSizeGuide, isMobile) => {
  const productName = product.description || product.material;
  return (
    <Modal show>
      <ModalContainer>
        {showSizeGuide
          ? <SizeGuide close={close} returnToInfos={() => setShowSizeGuide(false)} />
          : (
            <>
              {isMobile
                ? (
                  <Column>
                    {product.thumbnails.map((thumbnail) => <MobileImg key={thumbnail} src={thumbnail} alt={productName} />)}
                  </Column>
                )
                : (
                  <Slider {...sliderSettings} className="test">
                    {product.thumbnails.map((thumbnail) => <ModalImgContainer key={`modal_${thumbnail}`} image={thumbnail} productName={productName} />)}
                  </Slider>
                )}
              <InfosContainer>
                <ModalCross src={cross} alt="Fermer les infos produits" onClick={close} />
                <InfoName>{productName}</InfoName>
                {productInfo.map(({
                  slug, label, suffix, isArray,
                }) => {
                  if (!product[slug]) return null;
                  return (
                    <InfoLine key={slug}>
                      {label}
                      <InfoValue>{`${isArray ? product[slug].join(', ') : product[slug]}${suffix || ''}`}</InfoValue>
                    </InfoLine>
                  );
                })}
                <ShowSizeGuideButton onClick={() => setShowSizeGuide(true)}>Voir le guide des tailles</ShowSizeGuideButton>
              </InfosContainer>
            </>
          )}
      </ModalContainer>
    </Modal>
  );
};

const getUpdatedFilters = (filters, slug, value) => {
  const updatedFilters = { ...filters };
  if (!filters[slug]) {
    updatedFilters[slug] = [value];
  } else if (filters[slug].includes(value)) {
    updatedFilters[slug] = updatedFilters[slug].filter((filter) => filter !== value);
  } else {
    updatedFilters[slug].push(value);
  }
  return updatedFilters;
};

const sortFilters = {
  slug: 'sort',
  label: 'Trier',
  list: [
    { value: 'GROWING_PRICE', label: 'Prix croissant' },
    { value: 'DECREASING_PRICE', label: 'Prix décroissant' },
    { value: 'GROWING_LENGTH', label: 'Longueur croissante' },
    { value: 'DECREASING_LENGTH', label: 'Longueur décroissante' },
  ],
};

const renderSortFilter = (sortFilter, setSortFilter) => (
  <FilterSelect
    index={0}
    label={sortFilters.label}
    list={sortFilters.list}
    filters={sortFilter ? [sortFilter] : []}
    toggleFilter={(value) => setSortFilter(value === sortFilter ? undefined : value)}
  />
);

const ProductsSelector = ({ uptradeContext: { apiPartnerProducts, partnerProductsFilters }, context: { isMobile } }) => {
  const [perPage] = useState(4);
  const [offset, setOffset] = useState(0);
  const [filters, setFilters] = useState({});
  const [showFilters, toggleShowFilters] = useState(false);
  const [showSizeGuide, setShowSizeGuide] = useState(false);
  const [product, setProduct] = useState(undefined);
  const [sortFilter, setSortFilter] = useState(undefined);

  const handlePageClick = (offsetValue) => {
    setOffset(offsetValue);
  };
  const toggleFilter = (slug, value) => {
    const updatedFilter = getUpdatedFilters(filters, slug, value);
    setFilters(updatedFilter);
  };
  const filteredApiPartnerProducts = getFilteredApiPartnerProducts(apiPartnerProducts, filters, partnerProductsFilters);
  const sortedApiPartnerProducts = sortApiPartnerProducts(filteredApiPartnerProducts, sortFilter);
  const pageCount = Math.round(sortedApiPartnerProducts.length / perPage, 10);
  return (
    <Container>
      {renderHeader()}
      {isMobile && <FilterButton onClick={() => toggleShowFilters(!showFilters)} />}
      {(!isMobile || showFilters) && (
        <FiltersContainer>
          {renderSortFilter(sortFilter, setSortFilter)}
          {partnerProductsFilters.map(({ slug, label, list }, index) => (
            <FilterSelect key={slug} index={index + 1} label={label} list={list} filters={filters[slug]} toggleFilter={(value) => toggleFilter(slug, value)} />
          ))}
        </FiltersContainer>
      )}
      <NumberOfProducts>{`${sortedApiPartnerProducts.length} résultats`}</NumberOfProducts>
      <ProductCardsContainer>
        {isMobile ? renderMobile(sortedApiPartnerProducts, offset * perPage, (offset * perPage) + perPage, setProduct) : renderDesktop(filteredApiPartnerProducts, setProduct)}
      </ProductCardsContainer>
      {isMobile && pageCount > 0 && (
        <PageCountContainer>
          {renderArrowLeft({ isActive: offset !== 0, onClick: () => handlePageClick(offset - 1) })}
          <PageCount>{`${offset + 1} / ${pageCount}`}</PageCount>
          {renderArrowRight({ isActive: offset !== pageCount - 1, onClick: () => handlePageClick(offset + 1) })}
        </PageCountContainer>
      )}
      {product && renderInfosModal(product, () => { setProduct(undefined); setShowSizeGuide(false); }, showSizeGuide, setShowSizeGuide, isMobile)}
    </Container>
  );
};

ProductsSelector.propTypes = {
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
  uptradeContext: PropTypes.shape({
    apiPartnerProducts: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
    })),
    partnerProductsFilters: PropTypes.arrayOf(PropTypes.shape({
      pace: PropTypes.number,
    })),
  }).isRequired,
  theme: PropTypes.shape({
    colors: PropTypes.shape({}),
  }).isRequired,
};

export default withTheme(withAppContext(withUptradeContext(ProductsSelector)));
