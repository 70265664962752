import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { renderArrowDown, renderFilter } from './svgImages';

const Container = styled.div`
  border-radius: 3px;
  background-color: ${({ theme, isOpened }) => theme.colors[isOpened ? 'navy' : 'white']};
  color: ${({ theme, isOpened }) => theme.colors[isOpened ? 'white' : 'navy']};
  font-family: Roboto;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px;
  margin-bottom: ${({ isOpened }) => isOpened ? '20' : '30'}px;
  border: 1px solid ${({ theme, isOpened }) => theme.colors[isOpened ? 'navy' : 'blueGrey']};
`;

const Text = styled.p`
  margin: 0px 10px 0px 5px;
`;

const FilterButton = ({ onClick }) => {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <Container isOpened={isOpened} onClick={() => { setIsOpened(!isOpened); onClick(); }}>
      {renderFilter({ isActive: isOpened })}
      <Text>Filtres</Text>
      {renderArrowDown({ isActive: isOpened })}
    </Container>
  );
};

FilterButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default FilterButton;
